<template>
  <div class="admin-orders-listing">
    <title-bar title="New order" />
    <div class="admin-new-order">
      <stock-listing :cart="cart" @stockUpdate="updateCart" />
      <order-form action="new" :order-obj="order" :cart="cart" />
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import StockListing from '@/views/Admin/Stock/components/StockListing.vue'
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue'

export default {
  components: {
    TitleBar,
    StockListing,
    OrderForm
  },
  data() {
    return {
      cart: [],
      order: {
        streetAddress: null,
        streetNumber: null,
        postalCode: null,
        city: 'Vienna',
        gate: null,
        flatNumber: null,
        floorNumber: null,
        user: null,
        telephone: null,
        email: null,
        description: null,
        paymentMethod: null,
        discount: 0,
        items: [],
        fromWarehouse: false,
        shippingDate: null
      }
    }
  },
  methods: {
    updateCart(newProduct) {
      let updated = false
      this.cart.forEach(product => {
        if (product.id === newProduct.id) {
          updated = true
          if (newProduct.stock_update === 0) {
            const index = this.cart.indexOf(product)
            this.cart.splice(index, 1)
          } else {
            product.stock_update = newProduct.stock_update
          }
        }
      })
      if (!updated && newProduct.stock_update !== 0) {
        this.cart.push(newProduct)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>